import React, { createContext, useState, useEffect } from "react";
import cookie from "react-cookies";
const API_BASE_URL =
  process.env.GATSBY_AUTH_ENDPOINT || "https://test-auth.thomasnet.com";
const API_AMISIGNEDIN_PATH = "/a/amisignedin";
const THOMASNET_COOKIE_OPTIONS = { path: "/", domain: ".thomasnet.com" };

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authenticated, setAuthentication] = useState(null);

  const amISignedIn = async (tinid, corex, coreu) => {
    const model = {
      userid: tinid,
      core_x: corex,
      core_u: coreu
    };

    const path = `${API_BASE_URL}${API_AMISIGNEDIN_PATH}`;

    const user = await fetch(path, {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(data => data.json())
      .then(response => response)
      .catch(err => {
        return err;
      });
    return user;
  };

  const getAuthCookies = () => {
    return {
      tinid: cookie.load("tinid"),
      coreX: cookie.load("core_x"),
      coreU: cookie.load("core_u")
    };
    //to test on local, uncomment and hardcode the tinid, coreX, and coreU
    // return {
    //   tinid: "26098",
    //   coreX: "5d35d6e83d08f",
    //   coreU: "5d35d6e83d0499.49137565"
    // }
  };

  const signOut = () => {
    cookie.remove("tinid", THOMASNET_COOKIE_OPTIONS);
    cookie.remove("core_x", THOMASNET_COOKIE_OPTIONS);
    cookie.remove("core_u", THOMASNET_COOKIE_OPTIONS);
    setUser(null);
    setAuthentication(false);
  };

  useEffect(() => {
    async function getUser() {
      let { tinid, coreX, coreU } = getAuthCookies();
      const user = await amISignedIn(tinid, coreX, coreU);
      if (user && user.email) {
        setAuthentication(true);
        setUser(user);
      } else {
        setAuthentication(false);
        setUser(null);
      }
    }
    getUser();
  }, []);

  const context = {
    user,
    authenticated,
    signOut
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
