import React, { Component } from "react";

import SearchSuggestPreviewItem from "./search-suggest-preview-item";

class SearchSuggestPreview extends Component {
    static defaultProps = {
        isOpen: true,
        previewItems: [],
        previewResult: {},
        searchTerm: "",
        searchOption: "",

        onSelectItem: () => {
            console.log("search-suggest-global-preview.onSelectItem (default)");
        },
        onKeyDown: e => {
            console.log(`search-suggest-global-preview.onKeyDown (default). which: ${e.which}`);
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            // isOpen: this.props.isOpen,
            previewResult: this.props.previewResult,
            previewItems: this.props.previewItems,
            searchTerm: this.props.searchTerm,
            searchOption: this.props.searchOption
        };

        this.onItemClick = this.onItemClick.bind(this);

        this.renderSuppliersSearchSuggestItems = this.renderSuppliersSearchSuggestItems.bind(this);
        this.renderGenericSearchSuggestItems = this.renderGenericSearchSuggestItems.bind(this);

        // CSS CLASS CONSTANTS
        this.CSS_MENU = "search-suggest-preview-menu show";
        this.CSS_MENU_ITEM = "search-suggest-preview-item";
        this.CSS_MENU_NO_RESULTS = "search-suggest-preview-no-results";
        this.CSS_MENU_ITEM_HEADER = "search-suggest-preview-header";
        this.CSS_DISABLED = "disabled";

        // TW-2137: UNIQUE CLASSES PER HEADER TYPE
        this.CSS_MENU_CATEGORY_HEADER = "suggested-search__header--category";
        this.CSS_MENU_COMPANY_HEADER = "suggested-search__header--company";
        this.CSS_MENU_CATALOG_HEADER = "suggested-search__header--catalog";

        // UNIQUE CLASSES PER PREVIEW ITEM TYPE
        this.CSS_MENU_CATEGORY_ITEM = "suggested-search__result--category";
        this.CSS_MENU_COMPANY_ITEM = "suggested-search__result--company";
        this.CSS_MENU_CATALOG_ITEM = "suggested-search__result--catalog";
    }

    componentWillReceiveProps(newprops) {
        // console.log("search-suggest-preview componentWillReceiveProps fired...", newprops);
        if (newprops) {
            this.setState({
                // searchType:     newprops.searchType,
                // searchOption:   newprops.searchOption,
                // searchSuggest:  newprops.searchSuggest,
                searchTerm: newprops.searchTerm,
                searchOption: newprops.searchOption,
                previewResults: newprops.previewResults,
                previewItems: newprops.previewItems
                // placeholder:    newprops.placeholder
            });
        }
    }

    onItemClick(item, e) {
        console.log(`search-suggest-global-preview.onItemClick.`);

        if (this.props.onSelectItem) {
            this.props.onSelectItem(item, e);
        } else {
            console.warn("NO onSelectItem function found");
        }
    }

    renderSuppliersSearchSuggestItems() {
        console.log(`renderSuppliersSearchSuggestItems fired`);

        const cats = [
            {
                label: "Product / Service Categories",
                headerCssClass: this.CSS_MENU_CATEGORY_HEADER,
                itemCssClass: this.CSS_MENU_CATEGORY_ITEM
            },
            {
                label: "Company Names",
                headerCssClass: this.CSS_MENU_COMPANY_HEADER,
                itemCssClass: this.CSS_MENU_COMPANY_ITEM
            },
            {
                label: "Product Catalogs",
                headerCssClass: this.CSS_MENU_CATALOG_HEADER,
                itemCssClass: this.CSS_MENU_CATALOG_ITEM
            }
        ];
        var catsitems = [];
        var itemobjects = [];

        // separate the result items into categories
        cats.forEach((c, ci) => {
            var catitems = {
                cat: c.label,
                catHeaderCssClass: c.headerCssClass,
                catItemCssClass: c.itemCssClass,
                catkey: `cat_${ci}`,
                items: []
            };
            catitems.items = this.state.previewItems.filter(a => a.type === c.label);
            catsitems.push(catitems);
        });

        // console.log("******** catsitems are", catsitems);

        catsitems.forEach((catitem, idx) => {
            // only add the header and items if there are items in this cateogry
            if (catitem.items && catitem.items.length > 0) {
                var h5 = (
                    <h5
                        className={`${this.CSS_MENU_ITEM_HEADER} ${catitem.catHeaderCssClass}`}
                        key={`${catitem.catkey}_hdr`}>
                        {catitem.cat}
                    </h5>
                );
                itemobjects.push(h5);

                var links = catitem.items.map((i, ix) => {
                    // console.log(i);

                    // eslint-disable-next-line
                    return (
                        <SearchSuggestPreviewItem
                            key={`item_c${catitem.catkey}_${ix}`}
                            item={i}
                            searchTerm={this.state.searchTerm}
                            onSelectItem={this.onItemClick}
                            css={catitem.catItemCssClass}
                        />
                    );
                });

                itemobjects.push(...links);
            }
        });

        return itemobjects;
    }

    renderGenericSearchSuggestItems() {
        console.log(`renderGenericSearchSuggestItems fired`);

        var itemobjects = this.state.previewItems.map((i, ix) => {
            // console.log(i);

            // eslint-disable-next-line
            return (
                <SearchSuggestPreviewItem
                    key={`item_${i.value}_${ix}`}
                    item={i}
                    searchTerm={this.state.searchTerm}
                    onSelectItem={this.onItemClick}
                />
            );
        });

        return itemobjects;
    }

    render() {
        var itemobjects = [];

        if (this.state.previewItems && this.state.previewItems.length > 0) {
            if (this.state.searchOption === "search-suppliers") {
                itemobjects = this.renderSuppliersSearchSuggestItems();
            } else {
                itemobjects = this.renderGenericSearchSuggestItems();
            }

            if (this.state.previewResult && this.state.previewResult.viewall_url) {
                var viewallitem = (
                    <SearchSuggestPreviewItem
                        key={"item_view_all_99"}
                        css={"font-weight-bold font-italic suggested-search__view-all"}
                        item={{
                            value: "view-all",
                            title: "SEE ALL MATCHING RESULTS",
                            url: this.state.previewResult.viewall_url,
                            disabled: false,
                            subitem: false
                        }}
                        searchTerm={this.state.searchTerm}
                        onSelectItem={this.onItemClick}
                    />
                );

                itemobjects.push(viewallitem);
            }
        } else {
            var noresult = (
                <p className={this.CSS_MENU_NO_RESULTS}>
                    <em>No results match this search...</em>
                </p>
            );
            itemobjects.push(noresult);
        }

        return (
            <div className={this.CSS_MENU} onKeyDown={this.props.onKeyDown}>
                {itemobjects}
            </div>
        );
    }
}

export default SearchSuggestPreview;
