import React, { Component } from 'react';
import CustomSelectItem from './custom-select-item';


class CustomSelectMenu extends Component {

    static defaultProps = {
        menuIsOpen: false,
        selectedValue: '',
        optionItems: [],
        onMenuItemClick: (item, e) => { console.log(`custom-select-menu.onMenuItemClick (default) fired. `); },
        onMenuItemKeyDown: (item, e) => { console.log(`custom-select-menu.onMenuItemClick (default) fired. which: ${e.which}`); }
    };

    constructor(props) {
        super(props); 

        this.state = { 
            menuIsOpen: this.props.menuIsOpen,
            optionItems: this.props.optionItems,
            selectedValue: this.props.selectedValue
        };

    }

    componentWillReceiveProps(newprops) {
        if (newprops && newprops.selectedValue && newprops.selectedValue !== this.state.selectedValue) {
            this.setState({
                selectedValue: newprops.selectedValue
            });
        }
    }
  
    render() { 
        var show = this.props.menuIsOpen ? 'show ' : ''; 
        
        var ddMenuClass =   show + 'custom-select-menu';

        

        // var opts = this.props.menuItems; 
        var dditems = this.props.optionItems.map( (item, idx) => {
            var key = `${item.value}_${idx}`;
            var active = this.state.selectedValue === item.value;

            return <CustomSelectItem
                        active={active}
                        key={key}
                        item={item}
                        onSelect={this.props.onMenuItemClick}
                        onKeyDown={this.props.onMenuItemKeyDown}
                        />;

        });
        return (
            <div className={ddMenuClass} aria-labelledby="dropdownMenuButton">
                {dditems}
            </div>
        );

    }

}

export default CustomSelectMenu;
