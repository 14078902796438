import React, { Component } from "react";
import conversion from "../utils/convert-html-ascii";

class SearchSuggestPreviewItem extends Component {
    static defaultProps = {
        css: "",
        item: null,
        searchTerm: "",
        onSelectItem: () => {
            console.log("search-suggest-preview-item.onSelectItem (default)");
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            css: this.css,
            item: this.props.item,
            searchTerm: this.props.searchTerm
        };

        this.onClick = this.onClick.bind(this);
        this.getItemLabelText = this.getItemLabelText.bind(this);

        this.CSS_MENU_ITEM = "search-suggest-preview-item";

        this.CSS_DISABLED = "disabled";
        this.CSS_TERM_HIGHLIGHT = "term-highlight";
    }

    componentWillReceiveProps(newprops) {
        // console.log("************ componentWillReceiveProps fired. newprops:", newprops);
        if (newprops) {
            this.setState({
                css: newprops.css,
                searchTerm: newprops.searchTerm,
                item: newprops.item
            });
        }
    }

    onClick(e) {
        console.log(`search-suggest-preview-item.onClick.`);
        if (this.props.onSelectItem) {
            this.props.onSelectItem(this.props.item, e);
        } else {
            console.warn("NO onSelectItem handler found");
        }
    }

    getItemLabelText(title, subtitle) {
        var fulltext = `${unescape(title)}`; // (${subtitle})`;
        if (subtitle) {
            fulltext += ` (${unescape(subtitle)})`;
        }

        if (this.state.searchTerm) {
            var regex = new RegExp("(`${this.state.searchTerm}`)", "gi");
            var splits = fulltext.split(regex);
            var result = [];
            splits.forEach(a => {
                var spanclass = "";
                if (a.match(regex)) {
                    // result.push(<span className={this.CSS_TERM_HIGHLIGHT}>a</span>)
                    spanclass = this.CSS_TERM_HIGHLIGHT;
                }
                result.push(<span className={spanclass}>{a}</span>);
            });
            return result;
        } else {
            return <span>{fulltext}</span>;
        }
    }

    render() {
        var title = this.props.item ? conversion.ascii(this.props.item.title) : "";
        var subtitle = this.props.item ? this.props.item.subtitle : "";

        var css = this.CSS_MENU_ITEM;
        if (this.props.item.disabled) {
            css += " " + this.CSS_DISABLED;
        }
        if (this.props.css) {
            css += " " + this.props.css;
        }

        // eslint-disable-next-line
        return (
            <a
                href="#preview-item"
                className={css}
                data-item-title={title}
                data-item-subtitle={subtitle}
                onClick={this.onClick}>
                {/* <span className="term-highlight">{title}</span> ({subtitle}) */}
                {/* {title} ({subtitle}) */}
                {this.getItemLabelText(title, subtitle)}
            </a>
        );
    }
}

export default SearchSuggestPreviewItem;
