import React from "react";

export const SignIn = () => {
  return (
    <>
    <li className="site-nav__li-l1" id="nav-login">
      <a
        href="https://account.thomasnet.com/login.html"
        data-ad_click="UR"
        data-event_type="ilink"
      >
        <span>
          Login
        </span>
      </a>
    </li>
    <li className="site-nav__li-l1" id="nav-register">
      <a
        href="https://account.thomasnet.com/register.html"
        data-ad_click="UR"
        data-event_type="ilink"
      >
        <span className="btn btn-primary">
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon--base"
          >
            <title>Register</title>
            <g fill="currentColor" fillRule="evenodd">
              <path d="M20.605 18.138c1.252-1.539 1.941-3.598 1.941-5.795 0-4.47-2.885-8.107-6.433-8.107-3.547 0-6.432 3.637-6.432 8.107 0 2.197.69 4.256 1.941 5.796 1.21 1.489 2.806 2.308 4.491 2.308 1.686 0 3.281-.82 4.492-2.309z" />
              <path d="M21.041 19.802c-.083.09-.166.178-.25.263a6.526 6.526 0 0 1-.321.293c-.089.078-.176.158-.267.23-.111.09-.226.17-.342.253-.093.066-.184.135-.279.196-.119.076-.24.144-.363.212-.096.054-.19.11-.286.16-.127.062-.257.115-.387.17-.097.04-.191.086-.29.12-.137.052-.277.09-.418.13-.092.027-.185.06-.278.083-.158.038-.318.061-.478.087-.08.013-.159.032-.239.042a6.205 6.205 0 0 1-1.46-.002c-.079-.01-.155-.029-.233-.04-.162-.026-.324-.05-.483-.089-.093-.022-.181-.054-.272-.08-.143-.042-.286-.08-.426-.132-.096-.035-.188-.079-.282-.118-.133-.056-.265-.11-.394-.174-.095-.047-.187-.103-.28-.156-.125-.07-.25-.138-.37-.216-.094-.06-.183-.126-.274-.192-.117-.083-.233-.164-.346-.256-.09-.072-.177-.15-.265-.228a7.953 7.953 0 0 1-.322-.294c-.086-.085-.168-.175-.252-.265-.067-.073-.138-.139-.203-.214-4.175 1.319-6.925 4.963-7.167 8.078h24.598c-.242-3.114-2.992-6.76-7.166-8.076-.066.076-.137.142-.205.215z" />
            </g>
          </svg>{" "}
          Register
        </span>
      </a>
    </li>
    </>
  );
};
