import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import "@tpco/blueprint-styles/blueprint.scss";
import "../styles/layout.scss";

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <Header siteTitle={data.site.siteMetadata.title} />
          <section className="site-content bg--gray-light" role="main">
            {children}
          </section>
          <Footer />
        </React.Fragment >
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
