import React, { Component } from "react";
import ReactDOM from "react-dom";
import { debounce } from "throttle-debounce";
import { KEY_CODES } from "../globals/constants";
import searchActions from "../actions/search-actions";
import SearchSuggestPreview from "./search-suggest-preview";
import KittSpinner from "../spinners/kittSpinner";

class InputSearchQuery extends Component {
  static defaultProps = {
    shouldAutofocus: false,
    searchType: "global", // global || supplier-search
    searchOption: "search-all",
    searchOptions: [],
    searchSuggest: true,
    searchSuggestResults: [],
    searchSuggestIsOpen: false,
    searchTerm: "",
    placeholder: "Search Thomas",
    selectedRegion: "NA",

    // onSearchExecute:        null,
    onSearchSuggestSelect: null,
    onSearchTermChange: null
  };

  constructor(props) {
    super(props);

    // console.log(`input-search-query.cTor. pageSection: ${this.props.pageSection}. searchType: ${this.props.searchType}`);

    this.state = {
      searchType: this.props.searchType,
      searchOption: this.props.searchOption,
      searchOptions: this.props.searchOptions,
      selectedRegion: this.props.selectedRegion,

      lastTypedValue: this.props.searchTerm,
      searchSuggest: this.props.searchSuggest,
      searchSuggestResults: this.props.searchSuggestResults,
      searchSuggestIsOpen: this.props.searchSuggestIsOpen,
      searchTerm: this.props.searchTerm,
      placeholder: this.props.placeholder

      // , spinning: true
    };

    /** init internal event handlers */
    // this.onSearchOptionsChange = this.onSearchOptionsChange.bind(this);
    // this.onSearchOptionsRegionsChange = this.onSearchOptionsRegionsChange.bind(this);
    // this.onSearchExecuteClick = this.onSearchExecuteClick.bind(this);
    this.onSearchTermKeyDown = this.onSearchTermKeyDown.bind(this);
    this.onSearchTermChange = this.onSearchTermChange.bind(this);

    /** setup search suggest debouncer */
    this.debounceSearchPreview = debounce(300, this.executeSearchPreview);

    /** init public api event handlers */
    // this.onPreviewItemSelect = this.onPreviewItemSelect.bind(this);
    this.onSearchSuggestSelect = this.onSearchSuggestSelect.bind(this);
    // this.onSearchExecute =          this.onSearchExecute.bind(this);

    /** searchSuggest event handlers */
    this.addEvents = this.addEvents.bind(this);
    this.removeEvents = this.removeEvents.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.updateSearchTerm = this.updateSearchTerm.bind(this);

    this.MENU_CLASS = "search-suggest-preview-menu";
    this.MENU_ITEM_CLASS = "search-suggest-preview-item";
    this.DISABLED_CLASS = "disabled";
  }

  componentWillReceiveProps(newprops) {
    if (newprops) {
      this.setState({
        lastTypedValue: newprops.searchTerm,
        searchType: newprops.searchType,
        searchOption: newprops.searchOption,
        searchSuggest: newprops.searchSuggest,
        searchTerm: newprops.searchTerm,
        placeholder: newprops.placeholder,
        selectedRegion: newprops.selectedRegion
      });
    }
  }

  getContainer() {
    var container = ReactDOM.findDOMNode(this);
    // console.log("CONTAINER: ", container);
    return container;
  }

  addEvents() {
    ["click", "touchstart", "keyup"].forEach(event => {
      if (typeof document !== "undefined") {
        return document.addEventListener(event, this.handleDocumentClick, true);
      }
    });
  }

  removeEvents() {
    ["click", "touchstart", "keyup"].forEach(event => {
      if (typeof document !== "undefined") {
        return document.removeEventListener(
          event,
          this.handleDocumentClick,
          true
        );
      }
    });
  }

  handleDocumentClick(e) {
    if (
      e &&
      (e.which === 3 || (e.type === "keyup" && e.which !== KEY_CODES.tab))
    )
      return;
    const container = this.getContainer();

    // click is inside the component, so let any other handlers happen
    if (
      container.contains(e.target) &&
      container !== e.target &&
      (e.type !== "keyup" || e.which === KEY_CODES.tab)
    ) {
      return;
    }

    // click is outside the component, so toggle the menu closed
    this.toggleMenu(e);
  }

  handleKeyDown(e) {
    // console.log(`input-search-query.handleKeyDown fired. which: ${e.which}. searchSuggestIsOpen: ${this.state.searchSuggestIsOpen}`);
    // console.log("\t target:", e.target);

    e.preventDefault();

    if (this.props.disabled) return;

    const container = this.getContainer();
    const searchInput = container.querySelector(".search-query");
    // console.log("\t container:", container);

    // selects an item when the menu is open and space is keyed
    // TW-1222 - allow space to change search term instead of clicking selected item
    // if (e.which === KEY_CODES.space && this.props.menuIsOpen && container !== e.target) {
    //     e.target.click();
    // }

    if (e.which === KEY_CODES.esc || !this.state.searchSuggestIsOpen) {
      this.toggleMenu(e);
      // container.querySelector('[aria-expanded]').focus();
      // container.querySelector('.custom-select-menu').focus();
      if (container.querySelector(this.MENU_CLASS))
        container.querySelector(this.MENU_CLASS).focus();
      return;
    }

    if (this.state.searchSuggestIsOpen && KEY_CODES.tab === e.which) {
      this.toggleMenu(e);

      searchInput.focus();

      this.updateSearchTerm(searchInput.value);

      return;
    }

    if (this.state.searchSuggestIsOpen && KEY_CODES.enter === e.which) {
      this.toggleMenu(e);

      this.updateSearchTerm(searchInput.value);

      e.target.click();

      return;
    }

    if (
      this.state.searchSuggestIsOpen &&
      [KEY_CODES.esc, KEY_CODES.up, KEY_CODES.down].indexOf(e.which) === -1
    ) {
      if (
        typeof document !== "undefined" &&
        document.activeElement !== searchInput
      ) {
        searchInput.focus();
      }

      this.updateSearchTerm(searchInput.value + String.fromCharCode(e.which));
      return;
    }

    const items = container.querySelectorAll(
      `.${this.MENU_CLASS} .${this.MENU_ITEM_CLASS}:not(.${
        this.DISABLED_CLASS
      })`
    );
    // console.log("custom-select menuitems:", items);

    if (!items.length) return;

    let index = -1;
    for (let i = 0; i < items.length; i += 1) {
      if (items[i] === e.target) {
        index = i;
        break;
      }
    }

    if (e.which === KEY_CODES.up) {
      if (index === 0) {
        searchInput.focus();
        searchInput.value = this.state.lastTypedValue;
        return; // move focus back to search input (no longer in suggestions)
      }

      if (index > 0) {
        index -= 1;
      }
    }

    if (e.which === KEY_CODES.down && index < items.length - 1) {
      index += 1;
    }

    if (index < 0) {
      index = 0;
    }

    items[index].focus();

    this.updateSearchTerm(items[index].getAttribute("data-item-title"));
  }

  updateSearchTerm(value) {
    this.setState({
      searchTerm: value
    });
  }

  toggleMenu(e) {
    e.preventDefault();

    // console.log( 'input-search-query.toggleMenu fired. new value: ' + !this.state.searchSuggestIsOpen );

    this.setState(prevState => ({
      searchSuggestIsOpen: !prevState.searchSuggestIsOpen
    }));
  }

  onMenuItemClick(item, e) {
    // console.log( `input-search-query.onMenuItemClick. selected value: ${item.value}`);
  }

  onMenuItemKeyDown(item, e) {
    // console.log( `input-search-query.onMenuItemKeyDown fired. which: ${e.which}`);
    this.handleKeyDown(e);
  }

  onSearchExecuteClick(e) {
    // console.log(`input-search-query.onSearchExecuteClick. term: ${this.state.searchTerm}. type: ${this.state.searchType}`);
    // if(!this.state.searchTerm) {
    //     alert('NO SEARCH TERM');
    // } else {
    //     alert(`Execute Search: ${this.state.searchTerm}`);
    // }
    if (this.state.searchTerm) {
      if (!this.props.onSearchExecute) this.onSearchExecute(e);
      // default search
      else
        this.props.onSearchExecute({
          searchTerm: this.state.searchTerm,
          searchType: this.state.searchOption
        });
    }
  }

  onSearchTermKeyDown(e) {
    if (e.which === KEY_CODES.enter) {
      e.preventDefault();
      this.onSearchExecuteClick(e);
    }
    if (e.which === KEY_CODES.esc && this.state.searchSuggestIsOpen) {
      this.toggleMenu(e);
    }
    if (e.which === KEY_CODES.down && this.state.searchSuggestIsOpen) {
      e.preventDefault();
      const container = this.getContainer();
      const searchInput = container.querySelector(".search-query");
      const firstMenuItem = container.querySelector(
        `.${this.MENU_CLASS} .${this.MENU_ITEM_CLASS}`
      );

      if (
        typeof document !== "undefined" &&
        document.activeElement === searchInput
      ) {
        firstMenuItem.focus();
        this.updateSearchTerm(firstMenuItem.getAttribute("data-item-title"));
      }
    }
  }

  onSearchTermChange(e) {
    // if (e.target) {
    // console.log('input-search-query.onSearchTermChange --- ON QRY CHANGE ---');
    var val = e.target.value;
    this.setState({
      searchTerm: val
    });

    this.debounceSearchPreview();

    if (this.props.onSearchTermChange) {
      this.props.onSearchTermChange(val, e);
    }
  }

  executeSearchPreview() {
    if (!this.state.searchSuggest) return;

    if (this.state.searchTerm && this.state.searchTerm.length > 2) {
      var term = this.state.searchTerm;
      var searchoption = this.state.searchOption;
      var region = this.state.selectedRegion;
      var domestic = false;

      // searchoption = 'supplier-discovery-multi';

      // console.log(`input-search-query.executeSearchPreview. term: ${term}`);

      this.setState({
        spinning: true
      });
      searchActions
        .searchSuggest(term, searchoption, region, domestic)
        .then(res => {
          // console.log(`\t got [${res.hits}] results`);
          // setup preview dropdown
          this.setState({
            searchSuggestIsOpen: true,
            searchSuggestResults: res,
            spinning: false
          });
        })
        .catch(err => {
          console.error("Error fetching search preview", err);
          this.setState({
            spinning: false
          });
        });
    } else {
      this.setState({
        searchSuggestIsOpen: false,
        searchSuggestResults: []
      });
    }
  }

  onSearchExecute(e) {
    var msg = `input-search-query.onSearchExecute (default) q=${
      this.props.searchTerm
    } type=${this.props.searchType} region=${this.props.selectedRegion}`;
    // console.log(msg);

    // searchActions.searchExecute( this.state.searchTerm, this.state.searchOption, this.state.searchRegion );
  }

  onSearchSuggestSelect(item, e) {
    e.preventDefault();
    // var msg = `input-search-query.onSearchSuggestSelect (default) ${item.url}`;

    this.setState({
      searchSuggestResults: [],
      searchSuggestIsOpen: false
    });

    // console.log(msg);

    if (this.props.onSearchSuggestSelect) {
      this.props.onSearchSuggestSelect(item, e);
    } else {
      window.location = item.url;
    }
  }

  render() {
    // this.searchOptionsItems = this.state.searchType === 'global' ? searchOptionsMenuItems_global : searchOptionsMenuItems_supplier;

    var queryPlaceholder = this.state.placeholder;
    var sel = this.state.searchOptions.filter(
      a => a.value === this.state.searchOption
    );
    if (sel && sel.length > 0) {
      queryPlaceholder = sel[0].placeholder;
    }

    var preview = null;
    // if (this.state.searchSuggest && this.state.searchSuggestResults && this.state.searchSuggestResults.length > 0) {
    if (this.state.searchSuggest && this.state.searchSuggestIsOpen) {
      preview = (
        <SearchSuggestPreview
          isOpen={true}
          searchTerm={this.state.searchTerm}
          searchOption={this.state.searchOption}
          previewResult={this.state.searchSuggestResults}
          previewItems={this.state.searchSuggestResults.items}
          onSelectItem={this.onSearchSuggestSelect}
          onKeyDown={this.handleKeyDown}
        />
      );

      this.addEvents();
    } else {
      this.removeEvents();
    }

    let rootcss = "site-search__search-query-input-wrap search-suggest-preview";

    let spinner = null;
    if (this.state.spinning) {
      rootcss += " horizontal-spinner spinner-gray spinning";
      spinner = <KittSpinner />;
    }

    return (
      <div className={rootcss}>
        {spinner}

        <input
          maxLength="100"
          autoComplete="off"
          className="form-control form-control-lg search-query"
          placeholder={queryPlaceholder}
          value={this.state.searchTerm || ""}
          data-orig-value={this.state.lastTypedValue}
          onKeyDown={this.onSearchTermKeyDown}
          onChange={this.onSearchTermChange}
          autoFocus={this.props.shouldAutofocus}
        />

        {preview}
      </div>
    );
  }
}

export default InputSearchQuery;
