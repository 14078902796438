import React, { Component } from "react";
import { KEY_CODES } from "../globals/constants";

class CustomSelectItem extends Component {
  static defaultProps = {
    active: false,
    toggle: true,
    item: {
      label: "",
      value: "",
      disabled: false,
      subitem: false
    },
    onSelect: (item, e) => {
      console.log(
        `custom-select-item.onSelect (default) fired. value: ${item.value}`
      );
    },
    onKeyDown: (item, e) => {
      console.log(
        `custom-select-item.onKeyDown (default) fired. value: ${item.value}`
      );
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active
    };

    this.getTabIndex = this.getTabIndex.bind(this);

    this.onClick = this.onClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    // this.onCancel = this.onCancel.bind(this);
  }

  componentWillReceiveProps(newprops) {
    if (newprops && newprops.active !== this.state.active) {
      this.setState({
        active: newprops.active
      });
    }
  }

  onClick(e) {
    if (this.props.disabled || this.props.header || this.props.divider) {
      e.preventDefault();
      return;
    }

    // call the props select event
    if (this.props.onSelect) {
      this.props.onSelect(this.props.item, e);
    }
  }

  onKeyDown(e) {
    // if the space is clicked and there is an item with a value and not disabled, then click
    if (
      e.which === KEY_CODES.space &&
      this.props.item &&
      !this.props.item.disabled
    ) {
      e.preventDefault();
      this.onClick(e);
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(this.props.item, e);
    }
  }

  getTabIndex() {
    if (this.props.disabled || this.props.header || this.props.divider) {
      return "-1";
    }

    return "0";
  }

  render() {
    const tabIndex = this.getTabIndex();

    var classname = `custom-select-menu-item`;
    if (this.props.item.css) {
      classname += ` {this.props.item.css}`;
    }
    if (this.state.active) {
      classname += ` active`;
    }

    // var href = 'javascript:void(0);';
    var href = `#${this.props.item.value}`;
    var lbl = this.props.item.label;
    var val = this.props.item.value;

    if (this.props.item.subitem) {
      classname += " subitem";
      lbl = "\t" + lbl;
    }

    var newitem = null;
    if (this.props.item.disabled) {
      classname += " disabled";
      newitem = (
        <a className={classname} disabled>
          {lbl}
        </a>
      );
    } else {
      newitem = (
        <a
          tabIndex={tabIndex}
          className={classname}
          href={href}
          data-value={val}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
        >
          {lbl}
        </a>
      );
    }

    return newitem;
  }
}

export default CustomSelectItem;
