import React, { Component } from "react";
import CustomSelect from "../custom-select/custom-select";
import InputSearchQuery from "../input-search-query/input-search-query";
import searchOptionsMenuItems_global from "../store/searchOptions-global.json";
import searchOptionsMenuItems_supplier from "../store/searchOptions-supplier-discovery.json";
import searchOptionsRegionsMenuItems from "../store/coverageAreas.json";
import searchActions from "../actions/search-actions";

class SearchBox extends Component {
  static defaultProps = {
    pageSection: "header", // header || hero
    searchType: "global", // global || supplier-search
    searchOption: "search-suppliers",
    searchOptionItems: [],
    regionsOptionItems: [],
    searchSuggest: true,
    searchSuggestResults: [],
    searchTerm: "",
    selectedRegion: "NA",
    searchDomestic: false,
    onSearchExecute: null,
    onSearchSuggestSelect: null,
    onSearchOptionChange: null
  };

  constructor(props) {
    super(props);

    this.state = {
      pageSection: this.props.pageSection,
      searchType: this.props.searchType,
      searchOption: this.props.searchOption,
      searchOptionItems: [],
      regionsOptionItems: [],
      showRegions: false,
      selectedRegion: this.props.selectedRegion,
      searchSuggest: this.props.searchSuggest,
      searchSuggestResults: this.props.searchSuggestResults,
      searchTerm: this.props.searchTerm,
      searchDomestic: this.props.searchDomestic
    };

    /** init internal event handlers and functions */
    this.onSearchTermChange = this.onSearchTermChange.bind(this);
    this.onSearchOptionsChange = this.onSearchOptionsChange.bind(this);
    this.onSearchOptionsRegionsChange = this.onSearchOptionsRegionsChange.bind(
      this
    );
    this.onSearchExecuteClick = this.onSearchExecuteClick.bind(this);

    this.initSearchOptions = this.initSearchOptions.bind(this);
    this.initRegionsOptions = this.initRegionsOptions.bind(this);

    /** init public api event handlers */
    this.onSearchExecute = this.onSearchExecute.bind(this);
  }

  componentWillReceiveProps(newprops) {
    if (newprops) {
      this.setState({
        searchType: newprops.searchType,
        searchOption: newprops.searchOption,
        searchSuggest: newprops.searchSuggest,
        searchTerm: newprops.searchTerm,
        selectedRegion: newprops.selectedRegion,
        searchDomestic: newprops.searchDomestic
      });
    }
  }

  componentWillMount() {
    /** init search options  */
    this.initSearchOptions();
    /** init regions options */
    this.initRegionsOptions();
  }

  initSearchOptions() {
    var opts =
      this.state.searchType === "global"
        ? searchOptionsMenuItems_global
        : searchOptionsMenuItems_supplier;
    var sel = opts.filter(a => a.value === this.state.searchOption);
    var queryPlaceholder = this.state.placeholder,
      searchSuggest = this.state.searchSuggest;

    if (sel && sel.length > 0) {
      queryPlaceholder = sel[0].placeholder;
      searchSuggest = sel[0].searchSuggest;
      // console.log(`SETTING SEARCH SUGGEST: ${searchSuggest}`);
    }
    this.setState({
      searchOptionItems: opts,
      searchSuggest: searchSuggest,
      placeholder: queryPlaceholder
    });
  }
  initRegionsOptions() {
    var opts = [];
    searchOptionsRegionsMenuItems.forEach(c => {
      // var reg = searchOptionsRegionsMenuItems[c];
      opts.push({
        value: c.value,
        label: c.name,
        css: "",
        disabled: false,
        subitem: false
      });
    });
    this.setState({
      regionsOptionItems: opts
    });
  }

  onSearchTermChange(newterm, e) {
    console.log("search-box.onSearchTermChange - newterm: " + newterm);
    this.setState({
      searchTerm: newterm
    });

    if (this.props.onSearchTermChange) {
      this.props.onSearchTermChange(newterm, e);
    }
  }
  onSearchOptionsChange(newitem, e) {
    console.log("search-box.onSearchOptionsChange - newval: " + newitem.value);

    this.setState(prevState => ({
      searchOption: newitem.value,
      showRegions: newitem.value === "search-suppliers",
      placeholder: newitem.placeholder || "",
      searchSuggest: newitem.searchSuggest || false
    }));

    if (this.props.onSearchOptionChange) {
      this.props.onSearchOptionChange(newitem, e);
    }
  }
  onSearchOptionsRegionsChange(newoption) {
    // console.log('search-box.onSearchOptionsRegionsChange - newval: ' + newoption.value);

    this.setState(prevState => ({
      selectedRegion: newoption.value
    }));
  }

  onSearchExecuteClick(e) {
    console.log(
      `search-box.onSearchExecuteClick. term: *${
      this.state.searchTerm
      }* type: *${this.state.searchOption}*`
    );

    if (this.state.searchTerm) {
      if (!this.props.onSearchExecute) this.onSearchExecute(e);
      // default search
      else
        this.props.onSearchExecute({
          searchTerm: this.state.searchTerm,
          searchType: this.state.searchOption,
          selectedRegion: this.state.selectedRegion,
          searchDomestic: this.searchDomestic
        });
    }
  }

  onSearchExecute(e) {
    searchActions.searchExecute(
      this.state.searchTerm,
      this.state.searchOption,
      this.state.selectedRegion,
      this.state.searchDomestic
    );
  }

  render() {
    this.shrug = "¯\\_(ツ)_/¯";
    this.search_button_text = "Search";

    this.searchOptionsItems =
      this.state.searchType === "global"
        ? searchOptionsMenuItems_global
        : searchOptionsMenuItems_supplier;

    var sel = this.searchOptionsItems.filter(
      a => a.value === this.state.searchOption
    );

    var queryPlaceholder = this.state.placeholder;

    if (sel && sel.length > 0) {
      queryPlaceholder = sel[0].placeholder;
    }

    var searchOptions = (
      <CustomSelect
        cssDropdown="search-options"
        cssDropdownToggle="search-options-toggle"
        cssDropdownMenu=""
        selectedValue={this.state.searchOption}
        optionItems={this.searchOptionsItems}
        onChange={this.onSearchOptionsChange}
      />
    );

    var regionscss =
      "search-options-regions " +
      (this.state.searchOption === "search-suppliers" ? "" : "d-none");
    var searchOptionsRegions = null;
    if (this.state.searchType === "global") {
      searchOptionsRegions = (
        <CustomSelect
          cssDropdown={regionscss}
          cssDropdownToggle="search-options-toggle"
          cssDropdownMenu=""
          selectedValue={this.state.selectedRegion}
          optionItems={this.state.regionsOptionItems}
          onChange={this.onSearchOptionsRegionsChange}
        />
      );
    }

    var searchExecutor = (
      <button
        type="button"
        className="btn btn-primary search-execute"
        onClick={this.onSearchExecuteClick}
      >
        {this.search_button_text}
      </button>
    );

    if (this.props.pageSection === "header") {
      searchExecutor = (
        <button
          type="button"
          className="btn btn-primary search-execute icon"
          title="Search"
          onClick={this.onSearchExecuteClick}
        >
          <img
            src="https://cdn40.thomasnet.com/img40/ico-search.svg"
            alt="search"
          />
        </button>
      );
    }

    var autoFocusSearchInput = this.props.pageSection !== "header";

    return (
      <div className="site-header__section-header__utility">
        <form
          className="site-search form-inline"
          data-page-type="supplier-discovery"
          data-thcomponent="search-box"
        >
          <div className="form-group">
            <div className="site-search__input-wrap">
              {searchOptions}

              <InputSearchQuery
                // maxLength="100"
                // autoComplete="off"
                // className="form-control form-control-lg search-query"
                placeholder={queryPlaceholder}
                searchType={this.state.searchType}
                searchSuggest={this.state.searchSuggest}
                searchOption={this.state.searchOption}
                selectedRegion={this.state.selectedRegion}
                searchTerm={this.state.searchTerm}
                // onKeyDown={this.onSearchTermKeyDown}
                onSearchTermChange={this.onSearchTermChange}
                onSearchSuggestSelect={this.props.onSearchSuggestSelect}
                onSearchExecute={this.onSearchExecuteClick}
                shouldAutofocus={autoFocusSearchInput}
              />

              {searchOptionsRegions}
            </div>

            {searchExecutor}
          </div>
        </form>
      </div>
    );
  }
}

export default SearchBox;
