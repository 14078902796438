import React from "react";
import { ApolloProvider } from "react-apollo";
import AuthProvider from "./src/auth/AuthContext";
import { client } from "./apolloClient";
import Layout from "./src/components/layout"


export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>{element}</AuthProvider>
    </ApolloProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
