
export const KEY_CODES = {
    zero:  48,
    nine:  57,
    a:     65,
    z:     90,
    esc:   27,
    space: 32,
    tab:   9,
    up:    38,
    down:  40,
    enter: 13
};

export const PAGE_TYPES = {
    signup: "SignUp",
    login: "LogIn",
    forgot: "Forgot"
};

export const PAGE_ACTIONS = {
    form: "Form",
    password: "Password",
    wrong_password: "WrongPassword",
    submit: "Submit"
};