/* eslint-disable */
import React, { useContext, useState } from "react";
import { SignIn } from "./SignIn";
import MyAccount from "./MyAccount";
import { AuthContext } from "../auth/AuthContext";
import SiteHeaderSection from "./siteHeaderSection";

const Header = (props: any) => {
  const { authenticated, signOut } = useContext(AuthContext);
  const [isActive, setNav] = useState("");
  const { tabIndex } = props
  const loggedIn = authenticated ? "logged-in" : "";

  return (
    <React.Fragment>
      <div className={`site-wrap layout-default nav-${isActive} ${loggedIn}`}>
        <header className="site-header" role="banner" data-thdoc="S1">
          <div className="site-header__global">
            <div className="container container--site-header__global">
              <span className="site-header__mobile-icons-menu ">
                <span
                  className={`nav__ico-link sh-ico-menu menu-link ${isActive}`}
                  onClick={() => (isActive === "active" ? setNav("") : setNav("active"))}
                  onKeyPress={() => { }}
                  role="link"
                  tabIndex={tabIndex}
                >
                  <img src="https://cdn40.thomasnet.com/img40/ico-nav.svg" alt="" />
                </span>
              </span>

              <a href="https://www.thomasnet.com" className="site-header__logo">
                <img
                  src="https://cdn40.thomasnet.com/img40/thomas-logo-blue.svg"
                  alt="Thomas Logo"
                />
              </a>

              <div className={`site-nav-container ${isActive}`}>
                <div className="snc-header">
                  <span
                    className={`close-menu menu-link ${isActive}`}
                    onClick={() => setNav("")}
                    onKeyPress={() => { }}
                    role="link"
                  >
                    +
                </span>
                </div>

                <nav className="site-nav global-nav" role="navigation">
                  <ul className="site-nav__level-1">
                    <li className="site-nav__li-l1 menu-item-has-children">
                      <a
                        href="https://www.thomasnet.com/network/"
                        data-ad_click="UR"
                        data-event_type="ilink"
                      >
                        <span>Network</span>
                      </a>
                      <ul className="site-nav__level-2">
                        <li className="site-nav__li-l2">
                          <a
                            href="https://www.thomasnet.com/suppliers/"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Supplier Discovery</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://www.thomasnet.com/catalogs/"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Product Catalogs</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://cad.thomasnet.com/cadmodels.html"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>CAD Models</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://customquotes.thomasnet.com/"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Custom Quotes</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="site-nav__li-l1 menu-item-has-children">
                      <a
                        href="https://news.thomasnet.com/"
                        data-ad_click="UR"
                        data-event_type="ilink"
                      >
                        <span>Insights</span>
                      </a>
                      <ul className="site-nav__level-2">
                        <li className="site-nav__li-l2 menu-item-has-children">
                          <a href="" data-ad_click="UR" data-event_type="ilink">
                            <span>Topics</span>
                          </a>
                          <ul className="site-nav__level-3">
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/featured/browse/supply-chain"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Supply Chain</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/featured/browse/engineering-design"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Engineering & Design</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/featured/browse/sales-marketing"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Sales & Marketing</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/featured/browse/thomas-index"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Thomas Index</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/featured/browse/new-products"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>New Products</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/featured/browse/manufacturing-innovation"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Manufacturing & Innovation</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/industry-trends"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Industry Trends</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/featured/browse/career"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Career & Workforce</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://www.thomasnet.com/articles/daily-bite"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Daily Bite</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/company-news"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Company News</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://thomasnet.com/articles/"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Technical Guides</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://www.thomasnet.com/white-papers/"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>White Papers</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://news.thomasnet.com/featured/browse/thomas-index"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Thomas Index</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2 menu-item-has-children">
                          <a
                            href="https://news.thomasnet.com/submit-pr"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Submit Content</span>
                          </a>
                          <ul className="site-nav__level-3">
                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/lp-contribute-to-thomas-insights"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Contribute to Insights</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://news.thomasnet.com/submit-pr"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Submit Press Release</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://www.thomasnet.com/industry-update-newsletter/"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Subscribe</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="site-nav__li-l1 menu-item-has-children">
                      <a
                        href="https://business.thomasnet.com"
                        data-ad_click="UR"
                        data-event_type="ilink"
                      >
                        <span>Marketing</span>
                      </a>
                      <ul className="site-nav__level-2">
                        <li className="site-nav__li-l2 menu-item-has-children">
                          <a href="" data-ad_click="UR" data-event_type="ilink">
                            <span>Products</span>
                          </a>
                          <ul className="site-nav__level-3">
                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/network/"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Thomas Network</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/webtrax/"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Thomas WebTrax</span>
                              </a>
                            </li>

                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/connect/"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Thomas Connect</span>
                              </a>
                            </li>

                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/navigator/"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Thomas Navigator</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://business.thomasnet.com/marketing-services/"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Marketing Services</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://business.thomasnet.com/thomas-traceparts-cad-network/"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>CAD Solutions</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2 menu-item-has-children">
                          <a href="" data-ad_click="UR" data-event_type="ilink">
                            <span>Resources</span>
                          </a>
                          <ul className="site-nav__level-3">
                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/reviews"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Reviews</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/resources"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Free eBook Library</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/lp-contact-digital"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Digital Health Check</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/buyer-report"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>In-Market Buyer Report</span>
                              </a>
                            </li>
                            <li className="site-nav__li-l3">
                              <a
                                href="https://business.thomasnet.com/lp-thomas-media-kit"
                                data-ad_click="UR"
                                data-event_type="ilink"
                              >
                                <span>Media Kit</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://blog.thomasnet.com/"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Blog</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://business.thomasnet.com/events/together-for-industry"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Events & Webinars</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://business.thomasnet.com/contact"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Contact</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
                <nav className="site-nav  utility-nav">
                  <ul className="site-nav__level-1">
                    <li className="site-nav__li-l1 menu-item-has-children">
                      <a
                        href="https://business.thomasnet.com/about"
                        data-ad_click="UR"
                        data-event_type="ilink"
                      >
                        <span>About</span>
                      </a>
                      <ul className="site-nav__level-2">
                        <li className="site-nav__li-l2">
                          <a
                            href="https://business.thomasnet.com/about"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>About Us</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a href="/" data-ad_click="UR" data-event_type="ilink">
                            <span>Careers</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://business.thomasnet.com/press-room"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Press Room</span>
                          </a>
                        </li>
                        <li className="site-nav__li-l2">
                          <a
                            href="https://business.thomasnet.com/industry-awards"
                            data-ad_click="UR"
                            data-event_type="ilink"
                          >
                            <span>Industry Awards</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="site-nav__li-l1">
                      <a
                        href="https://business.thomasnet.com/get-listed-on-thomasnet"
                        data-ad_click="UR"
                        data-event_type="ilink"
                      >
                        <span>List Your Business</span>
                      </a>
                      <ul className="site-nav__level-2" />
                    </li>
                    {(authenticated && <MyAccount signOut={signOut} />) || (
                      <SignIn />
                    )}
                  </ul>
                </nav>
              </div>
              <span href="" onClick={() => setNav("")} className={`site-nav-container-screen menu-link ${isActive}`}>
                &nbsp;
            </span>
            </div>
          </div>
          <SiteHeaderSection />
        </header>
      </div>
    </React.Fragment >
  );
};

export default Header;
