var entityPairs = [
    { character: '&', html: '&amp;', ascii: '&#38;' },
    { character: '<', html: '&lt;', ascii: '&#60;' },
    { character: '>', html: '&gt;', ascii: '&#62;' },
    { character: '"', html: '&quot;', ascii: '&#34;' },
    { character: '©', html: '&copy;', ascii: '&#169;' },
    { character: '®', html: '&reg;', ascii: '&#174;' },
    { character: '™', html: '&trade;', ascii: '&#8482;' }
];

const convertHTML = (str) => {
    entityPairs.forEach(function (pair) {
        var reg = new RegExp(pair.html, 'g');

        str = str.replace(reg, pair.character);
    });

    return str;
}

const convertASCII = (str) => {
    entityPairs.forEach(function (pair) {
        var reg = new RegExp(pair.ascii, 'g');

        str = str.replace(reg, pair.character);
    });

    return str;
}

const conversion = {
    ascii: convertASCII,
    html: convertHTML
};

export default conversion;
