import React, { Component } from "react";
import ReactDOM from "react-dom";
import CustomSelectMenu from "./custom-select-menu";
import { KEY_CODES } from "../globals/constants";

/**
 * CustomSelect is a component is based on the same markup and css as the bootstrap dropdown component.
 * however, the behavior is slightly different in that it functions like a select form control more than a navigation list.
 *
 *
 * CustomSelect API
 * props:
 * - cssDropdown: additional css to apply to the dropdown container
 * - cssDropdownToggle: additional css to apply to the dropdown toggle anchor tag
 * - cssDropdownMenu: additional css to apply to the css menu
 * - selectedValue: the string 'value' property to set as the intial dropdown value
 * - optionItems: a json array of objects to use to create the dropdown menu
 * - onChange: a function that will be called when the dropdown value changes (on new value select)
 *              the dropdown calls the function with the selected menu item object the first parameter and the original click event as the second
 *
 */

class CustomSelect extends Component {
  static defaultProps = {
    /** additional css classes for the component parts **/
    cssDropdown: "",
    cssDropdownToggle: "",
    cssDropdownMenu: "",
    disabled: false,

    /** selected value. must match the .value prop in one of the optionItems **/
    selectedValue: "",
    optionItems: [],

    /** callback for 'selectedValue' change **/
    onChange: newval => {
      console.log(`CustomSelect.onChange fired. newval: ${newval}`);
    },

    /**
     * Option to allow for empty string values. This is useful if you need to have a
     * custom placeholder when no value is selected.
     * **/
    useEmptyValue: false
  };

  constructor(props) {
    super(props);

    this.state = {
      menuIsOpen: false,
      selectedValue: props.selectedValue,
      disabled: props.disabled
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onMenuItemKeyDown = this.onMenuItemKeyDown.bind(this);

    this.addEvents = this.addEvents.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.removeEvents = this.removeEvents.bind(this);
    // this.toggle = this.toggle.bind(this);

    this.ROOT_CLASS = "thm-custom-select";
    this.MENU_TOGGLE_CLASS = "custom-select-toggle";
    this.MENU_CLASS = "custom-select-menu"; //mapToCssModules('dropdown-menu', this.props.cssModule);
    this.MENU_ITEM_CLASS = "custom-select-menu-item"; //mapToCssModules('dropdown-item', this.props.cssModule);
    this.DISABLED_CLASS = "disabled"; //mapToCssModules('disabled', this.props.cssModule);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedValue: nextProps.selectedValue,
      disabled: nextProps.disabled
    });
  }

  getContainer() {
    var container = ReactDOM.findDOMNode(this);
    // console.log("CONTAINER: ", container);
    return container;
  }

  addEvents() {
    ["click", "touchstart", "keyup"].forEach(event => {
      if (typeof document !== "undefined") {
        return document.addEventListener(event, this.handleDocumentClick, true);
      }
    });
  }

  removeEvents() {
    ["click", "touchstart", "keyup"].forEach(event => {
      if (typeof document !== "undefined") {
        return document.removeEventListener(
          event,
          this.handleDocumentClick,
          true
        );
      }
    });
  }

  handleDocumentClick(e) {
    if (
      e &&
      (e.which === 3 || (e.type === "keyup" && e.which !== KEY_CODES.tab))
    )
      return;
    const container = this.getContainer();

    // click is inside the component, so let any other handlers happen
    if (
      container.contains(e.target) &&
      container !== e.target &&
      (e.type !== "keyup" || e.which === KEY_CODES.tab)
    ) {
      return;
    }

    this.toggleMenu(e);
  }

  handleKeyDown(e) {
    // console.log(`custom-select.handleKeyDown fired. which: ${e.which}. menuIsOpen: ${this.state.menuIsOpen}`);
    // console.log("\t target:", e.target);

    if (
      [KEY_CODES.esc, KEY_CODES.up, KEY_CODES.down, KEY_CODES.space].indexOf(
        e.which
      ) === -1 ||
      (/button/i.test(e.target.tagName) && e.which === KEY_CODES.space) ||
      /input|textarea/i.test(e.target.tagName)
    ) {
      return;
    }

    e.preventDefault();

    if (this.props.disabled) return;

    const container = this.getContainer();
    // console.log("\t container:", container);

    // selects an item when the menu is open and space is keyed
    if (
      e.which === KEY_CODES.space &&
      this.props.menuIsOpen &&
      container !== e.target
    ) {
      e.target.click();
    }

    if (e.which === KEY_CODES.esc || !this.state.menuIsOpen) {
      this.toggleMenu(e);
      // container.querySelector('[aria-expanded]').focus();
      // container.querySelector('.custom-select-menu').focus();
      if (container.querySelector(this.MENU_CLASS))
        container.querySelector(this.MENU_CLASS).focus();
      return;
    }

    const items = container.querySelectorAll(
      `.${this.MENU_CLASS} .${this.MENU_ITEM_CLASS}:not(.${
        this.DISABLED_CLASS
      })`
    );
    // console.log("custom-select menuitems:", items);

    if (!items.length) return;

    let index = -1;
    for (let i = 0; i < items.length; i += 1) {
      if (items[i] === e.target) {
        index = i;
        break;
      }
    }

    if (e.which === KEY_CODES.up && index > 0) {
      index -= 1;
    }

    if (e.which === KEY_CODES.down && index < items.length - 1) {
      index += 1;
    }

    if (index < 0) {
      index = 0;
    }

    items[index].focus();
  }

  toggleMenu(e) {
    e.preventDefault();

    if (this.state.disabled) {
      return;
    }

    this.setState(prevState => ({
      menuIsOpen: !prevState.menuIsOpen
    }));
  }

  onMenuItemClick(item, e) {
    this.setState(prevState => ({
      selectedValue: item.value
    }));

    this.props.onChange(item, e);
    this.toggleMenu(e);
  }
  onMenuItemKeyDown(item, e) {
    // console.log(`custom-select.onMenuItemKeyDown fired. which: ${e.which}`);
    this.handleKeyDown(e);
  }

  render() {
    // var ddClass =       'thm-custom-select ' + this.props.cssDropdown;
    var ddClass = `${this.ROOT_CLASS} ${this.props.cssDropdown}`;
    if (this.state.disabled) {
      ddClass += ` ${this.DISABLED_CLASS}`;
    }

    var ddToggleClass = "custom-select-toggle " + this.props.cssDropdownToggle;

    const { useEmptyValue } = this.props;

    var toggleLabel = "Select Option";
    if (this.state.selectedValue || useEmptyValue) {
      var selectedItem = this.props.optionItems.filter(
        a => a.value === this.state.selectedValue
      );
      if (selectedItem && selectedItem.length > 0)
        toggleLabel = selectedItem[0].label;
    }

    if (this.state.menuIsOpen) {
      // console.log('Dropdown render - menuIsOpen: ' + this.state.menuIsOpen);
      this.addEvents();
    } else {
      this.removeEvents();
    }

    var menu = (
      <CustomSelectMenu
        menuIsOpen={this.state.menuIsOpen}
        selectedValue={this.state.selectedValue}
        optionItems={this.props.optionItems}
        onMenuItemClick={this.onMenuItemClick}
        onMenuItemKeyDown={this.onMenuItemKeyDown}
      />
    );

    // var toggle_click = this.state.disabled ? null : this.toggleMenu;
    var toggle_keydown = this.state.disabled ? null : this.handleKeyDown;

    return (
      <div className={ddClass}>
        <a
          href={`#${this.MENU_TOGGLE_CLASS}`}
          className={ddToggleClass}
          onClick={this.toggleMenu}
          onKeyDown={toggle_keydown}
        >
          <span>{toggleLabel}</span>
        </a>

        {menu}
      </div>
    );
  }
}

export default CustomSelect;
