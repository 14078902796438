import React, { useContext } from "react";
import { AuthContext } from "../auth/AuthContext";

const year = new Date().getFullYear();

const Footer = () => {
  const { authenticated } = useContext(AuthContext);
  return (
    <>
      <div className="site-footer" role="contentinfo" data-thdoc="S2">
        <div className="container site-footer__container">
          <div className="site-footer__body">
            <div className="titled-list titled-list--footer">
              <h3 className="titled-list__header">
                <a
                  href="https://www.thomasnet.com/network/"
                  data-ad_click="Footer"
                  data-event_type="ilink"
                >
                  Network
              </a>
              </h3>
              <ul className="titled-list__list">
                <li>
                  <a
                    href="https://www.thomasnet.com/suppliers/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Supplier Discovery
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/catalogs/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Product Catalogs
                </a>
                </li>
                <li>
                  <a
                    href="https://cad.thomasnet.com/cadmodels.html"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    CAD
                </a>
                </li>
                <li>
                  <a
                    href="https://certifications.thomasnet.com/certifications/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Diversity
                </a>
                </li>
                <li>
                  <a
                    href="https://customquotes.thomasnet.com/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Custom Quotes
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/reviews"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Buyer &amp; Engineer Reviews
                </a>
                </li>
              </ul>
            </div>
            <div className="titled-list titled-list--footer">
              <h3 className="titled-list__header">
                <a
                  href="https://business.thomasnet.com/marketing-services"
                  data-ad_click="Footer"
                  data-event_type="ilink"
                >
                  Marketing
              </a>
              </h3>
              <ul className="titled-list__list">
                <li>
                  <a
                    href="https://business.thomasnet.com/advertise"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Advertise
                </a>
                </li>
                <li>
                  <a
                    href="https://business.thomasnet.com/product-data-solutions"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Content &amp; Data Services
                </a>
                </li>
                <li>
                  <a
                    href="https://business.thomasnet.com/marketing-services/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Marketing Services
                </a>
                </li>
                <li>
                  <a
                    href="https://business.thomasnet.com/reviews/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    ThomasNet Testimonials
                </a>
                </li>
                <li>
                  <a
                    href="https://business.thomasnet.com/reviews/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    ThomasNet Reviews
                </a>
                </li>
                <li>
                  <a
                    href="https://business.thomasnet.com/get-listed-on-thomasnet"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Get Started
                </a>
                </li>
                <li>
                  <a
                    href="https://business.thomasnet.com/webtrax/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Thomas WebTrax
                </a>
                </li>
                <li>
                  <a
                    href="https://business.thomasnet.com/events/together-for-industry"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Events & Webinars
                </a>
                </li>
              </ul>
            </div>
            <div className="titled-list titled-list--footer">
              <h3 className="titled-list__header">
                <a
                  href="https://news.thomasnet.com"
                  data-ad_click="Footer"
                  data-event_type="ilink"
                >
                  Insights
              </a>
              </h3>
              <ul className="titled-list__list">
                <li>
                  <a
                    href="https://news.thomasnet.com"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Topics
                </a>
                </li>
                <li>
                  <a
                    href="https://news.thomasnet.com/featured/browse/thomas-index"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Thomas Index
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/articles/daily-bite/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Daily Bite
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/articles/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Guides
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/white-papers/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    White Papers
                </a>
                </li>
                <li>
                  <a
                    href="https://certifications.thomasnet.com/certifications/glossary/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Certification Glossary
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/industry-update-newsletter/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Subscribe
                </a>
                </li>
              </ul>
            </div>
            <div className="titled-list titled-list--footer">
              <h3 className="titled-list__header">
                <span>Site Maps</span>
              </h3>
              <ul className="titled-list__list">
                <li>
                  <a
                    href="https://www.thomasnet.com/browse/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Categories
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/browsecompanies.html"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Featured Companies
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/browsecategories/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Featured Categories
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/catalogs/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Featured Products
                </a>
                </li>
                <li>
                  <a
                    href="https://www.thomasnet.com/browsecatalogs/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Featured Catalogs
                </a>
                </li>
              </ul>
            </div>
            <div className="titled-list titled-list--footer">
              <h3 className="titled-list__header">
                <a
                  href="https://business.thomasnet.com/about"
                  data-ad_click="Footer"
                  data-event_type="ilink"
                >
                  About
              </a>
              </h3>
              <ul className="titled-list__list">
                <li>
                  <a
                    href="https://careers.thomasnet.com"
                    data-ad_click="Footer"
                    data-client_ip="73.194.109.208"
                    data-event_type="link"
                    data-hierarchy_1="TNET"
                    data-hierarchy_2="FOOTER"
                    data-hierarchy_3="CAREERS"
                    data-servercallhost="www.thomasnet.com"
                  >
                    Careers
                </a>
                </li>
                <li>
                  <a
                    href="https://business.thomasnet.com/press-room"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Press Room
                </a>
                </li>
                <li>
                  <a
                    href="https://clientcenter.thomasnet.com"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Client Center
                </a>
                </li>
                {authenticated ? (
                  <li>
                    <a
                      href="https://www.thomasnet.com/mythomas/"
                      data-ad_click="Footer"
                      data-event_type="ilink"
                    >
                      My Account
                </a>
                  </li>
                ) : (
                    <>
                      <li>
                        <a
                          href="https://account.thomasnet.com/register.html"
                          data-ad_click="Footer"
                          data-event_type="ilink"
                        >
                          Sign Up
                </a>
                      </li>
                      <li>
                        <a
                          href="https://account.thomasnet.com/login.html"
                          data-ad_click="Footer"
                          data-event_type="ilink"
                        >
                          Sign In
              </a>
                      </li>
                    </>
                  )}

                <li>
                  <a
                    href="https://www.thomasnet.com/contact/"
                    data-ad_click="Footer"
                    data-event_type="ilink"
                  >
                    Contact
                </a>
                </li>
              </ul>
              <div className="social-icons">
                <span>
                  <a
                    href="https://www.linkedin.com/company/thomasforindustry"
                    target="_blank"
                    data-client_ip="73.194.109.208"
                    data-event_type="link"
                    data-hierarchy_1="TNET"
                    data-hierarchy_2="FOOTER"
                    data-hierarchy_3="LINKEDIN"
                    data-servercallhost="www.thomasnet.com"
                  >
                    <img
                      src="https://cdn40.thomasnet.com/img40/ico-linkedin.svg"
                      data-png-fallback="https://cdn40.thomasnet.com/img40/ico-linkedin.png"
                      alt="LinkedIn"
                    />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.facebook.com/THOMASNET-97220725556/"
                    target="_blank"
                    data-client_ip="73.194.109.208"
                    data-event_type="link"
                    data-hierarchy_1="TNET"
                    data-hierarchy_2="FOOTER"
                    data-hierarchy_3="FACEBOOK"
                    data-servercallhost="www.thomasnet.com"
                  >
                    <img
                      src="https://cdn40.thomasnet.com/img40/ico-facebook.svg"
                      data-png-fallback="https://cdn40.thomasnet.com/img40/ico-facebook.png"
                      alt="Facebook"
                    />
                  </a>
                </span>
                <span>
                  <a
                    href="https://twitter.com/ThomasNet"
                    target="_blank"
                    data-client_ip="73.194.109.208"
                    data-event_type="link"
                    data-hierarchy_1="TNET"
                    data-hierarchy_2="FOOTER"
                    data-hierarchy_3="TWITTER"
                    data-servercallhost="www.thomasnet.com"
                  >
                    <img
                      src="https://cdn40.thomasnet.com/img40/ico-twitter.svg"
                      data-png-fallback="https://cdn40.thomasnet.com/img40/ico-twitter.png"
                      alt="Twitter"
                    />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.youtube.com/user/ThomasNetVideo"
                    target="_blank"
                    data-client_ip="73.194.109.208"
                    data-event_type="link"
                    data-hierarchy_1="TNET"
                    data-hierarchy_2="FOOTER"
                    data-hierarchy_3="YOUTUBE"
                    data-servercallhost="www.thomasnet.com"
                  >
                    <img
                      src="https://cdn40.thomasnet.com/img40/ico-youtube.svg"
                      data-png-fallback="https://cdn40.thomasnet.com/img40/ico-youtube.png"
                      alt="YouTube"
                    />
                  </a>
                </span>
                <span>
                  <a href="https://www.instagram.com/thomasforindustry/"
                    target="_blank"
                    data-client_ip="198.252.136.10"
                    data-event_type="link"
                    data-hierarchy_1="TNET"
                    data-hierarchy_2="FOOTER"
                    data-hierarchy_3="INSTAGRAM"
                    data-servercallhost="www.thomasnet.com">
                    <img src="https://cdn40.thomasnet.com/img40/ico-instagram.svg" data-png-fallback="https://cdn40.thomasnet.com/img40/ico-instagram.png" alt="Instagram" />
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="site-footer__copyright">
            <div className="container site-footer__copyright__container">
              <p>
                Copyright© {`${year}`} Thomas Publishing Company. All Rights Reserved.
              See {" "}
                <a href="https://www.thomasnet.com/terms.html">
                  Terms and Conditions
              </a>
                ,{" "}
                <a href="https://www.thomasnet.com/privacy.html" rel="nofollow">
                  Privacy Statement
              </a>{" "}
                and{" "}
                <a
                  href="https://www.thomasnet.com/do-not-track.html"
                  rel="nofollow"
                >
                  California Do Not Track Notice
              </a>
                . Website Last Modified June 20, 2019.
              <br />
                <a href="https://www.thomasnet.com/companyhistory/ThomasRegister_today.html">
                  Thomas Register®
              </a>{" "}
                and {" "}
                <a href="https://www.thomasnet.com/companyhistory/ThomasRegional.html">
                  Thomas Regional®
              </a>{" "}
                are part of ThomasNet.com. ThomasNet Is A Registered Trademark Of
                Thomas Publishing Company.
            </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer;
