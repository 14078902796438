import request from 'superagent'; 

const 
    domain = "https://www.thomasnet.com",
    search_suggest_path = "/scripts/search-suggest-40.php",
    search_execute_path = "/search-execute"
    ;

var searchExecute = (term, searchType, searchRegion, domestic) => {
    console.log("Executing Search....", term, searchType, searchRegion, domestic)
    if (!term) { term = ''; }
    if (!searchType) { searchType = 'search-suppliers'; }
     
    const qryparams = [
        `q=${encodeURIComponent(term)}`,
        `search_type=${searchType}`,
        `domestic=${domestic ? '1':'0'}`,
        `cov=${searchRegion ? searchRegion : 'NA' }`
    ];

    // var searchTags = $('body').attr('data-search-tags');
    // if(searchTags) { qryparams.push(`search_tags=${encodeURIComponent(searchTags)}`); }

    const url = `${domain}${search_execute_path}?` + qryparams.join('&');
    
    window.location = url; 

}

var searchSuggest = (term, searchType, searchRegion, domestic) => {

    const qryparams = {
        q: term,
        search_type: searchType,
        limit: 15,
        domestic: domestic ? '1':'0',
        format: 'json',
        cov: searchRegion
    };
    const url = `${domain}${search_suggest_path}?`; // + qryparams.join('&');

    return new Promise( (resolve, reject) => {

        request
            .get(url)
            .query(qryparams) // automatically uri encodes the param values
            .end(function(err, res){
                if (err) {
                    reject(err); 

                } else {
                    resolve(res.body.results); 

                }
            });

    });

}

var supplierSearchFilter = (params) => {
    const DOMAIN = process.env.REACT_APP_SEARCH_SUPPLIERS_DOMAIN; 
    const SUPPLIERS_SEARCH_PATH = process.env.REACT_APP_SEARCH_SUPPLIERS_PATH;
    const url = `${DOMAIN}${SUPPLIERS_SEARCH_PATH}`;

    return new Promise((resolve, reject) => {
        request
            .get(url)
            .query(params)
            .then((res)=>{
                resolve(res);
            })
            .catch((err)=>{
                reject(err);
            });
    });    
};

const searchActions = {
    searchSuggest: searchSuggest,
    searchExecute: searchExecute,
    supplierSearchFilter: supplierSearchFilter
};

export default searchActions;
