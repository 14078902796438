import React from "react";
import SearchBox from "./search/search-box/search-box";
import $ from "jquery";

class SiteHeaderSection extends React.Component {
    state = {
        sectionNavHeaderActive: "",
        sectionNavHeader: "",
        headerHeight: 0,
        hideSearch: false
    }

    handleSectionNavClicks = () => {
        const { sectionNavHeader, sectionNavHeaderActive } = this.state;
        if (sectionNavHeader === "section-nav-header") {
            this.setState({
                sectionNavHeader: "",
                sectionNavHeaderActive: "",
                hideSearch: false
            })
        } else {
            this.setState({
                sectionNavHeader: "section-nav-header",
                sectionNavHeaderActive: "active",
                hideSearch: true
            })
        }
    }

    componentDidMount() {
        if ($) {
            const winHeight = $(window).height() || 0;
            const docHeight = $(document).height() || 0;
            const headerglobalheight = $(".site-header__global").height();
            const max = docHeight - winHeight;
            this.setState({
                ...this.state,
                winHeight: winHeight,
                docHeight: docHeight,
                progMax: max,
                headerHeight: headerglobalheight
            }, () => console.log("state is --->", this.state));

            $(document).on('scroll', this.onWindowScroll);
        }
    }

    onWindowScroll = () => {
        const scrollVal = $(window).scrollTop() || 0;
        this.setState({
            ...this.state,
            scrollVal: scrollVal,
            progressValue: scrollVal
        });
        if ($('.site-header .site-header__section').length > 0) {
            if (scrollVal >= this.state.headerHeight) {
                $('body').addClass('fix-header');
            } else {
                $('body').removeClass('fix-header');
            }
        }
    }

    render() {
        const { sectionNavHeader, sectionNavHeaderActive, hideSearch } = this.state
        return (
            <div className="site-header__section">
                <div className={`container container--site-header__section ` + `${sectionNavHeader}`}>
                    <div className={`d-flex ${sectionNavHeaderActive}`}>
                        <div className="section-nav__title" onClick={() => this.handleSectionNavClicks()}>
                            <a
                                href="https://www.thomasnet.com/network/"
                                className=""
                                data-ad_click="UR"
                                data-event_type="ilink"
                            >
                                Network
                    </a>
                            <span className={`section-nav__title__arrow  ${sectionNavHeaderActive}`} />
                        </div>
                        <nav className={`site-nav section-nav ${sectionNavHeaderActive}`} role="navigation">
                            <ul className="site-nav__level-1">
                                <li className="site-nav__li-l1">
                                    <a
                                        href="https://www.thomasnet.com/suppliers/"
                                        data-ad_click="UR"
                                        data-event_type="ilink"
                                    >
                                        <span>Supplier Discovery</span>
                                    </a>
                                </li>
                                <li className="site-nav__li-l1">
                                    <a
                                        href="https://www.thomasnet.com/catalogs/"
                                        data-ad_click="UR"
                                        data-event_type="ilink"
                                    >
                                        <span>Product Catalogs</span>
                                    </a>
                                </li>
                                <li className="site-nav__li-l1">
                                    <a
                                        href="https://cad.thomasnet.com/cadmodels.html"
                                        data-ad_click="UR"
                                        data-event_type="ilink"
                                    >
                                        <span>CAD Models</span>
                                    </a>
                                </li>
                                <li className="site-nav__li-l1">
                                    <a
                                        href="https://customquotes.thomasnet.com/"
                                        data-ad_click="UR"
                                        data-event_type="ilink"
                                    >
                                        <span>Custom Quotes</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {!hideSearch && (<SearchBox />)}
                </div>
            </div>
        )
    }
}

export default SiteHeaderSection;